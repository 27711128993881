<template>
    <b-modal
        id="create-categoria-modal"
        header-bg-variant="primary"
        hide-footer
        no-close-on-backdrop
        :body-class="`${Theme === 'dark' && 'dark'} dialog2`"
        dialog-class="dialog"
        centered
        title="Crear categoría"
        @shown="openModal"
        @close="resetFom"
    >
        <b-card class="mb-4 w-100">
            <div>
                <b-form class="p-5">
                    <div class="d-flex">
                        <div>
                            <h3 class="mb-2">Datos de la categoría</h3>
                            <div class="text-gray mb-5">
                                Por favor registre los datos de la categoría
                                para continuar
                            </div>
                        </div>
                    </div>
                    <b-row>
                        <b-col cols="12" md="3" lg="3" xl="3">
                            <b-form-group>
                                <label for="codigo_cat">Código</label>
                                <b-form-input
                                    id="codigo_cat"
                                    type="text"
                                    placeholder="Ej: 001"
                                    v-model="$v.categoria.codigo.$model"
                                    :state="validateState('codigo')"
                                    :disabled="read_only"
                                >
                                </b-form-input>
                                <div
                                    class="length-field mt-1"
                                    v-if="!$v.categoria.codigo.$anyError"
                                >
                                    ({{ $v.categoria.codigo.$model.length }}
                                    /
                                    {{
                                        $v.categoria.codigo.$params.maxLength
                                            .max
                                    }})
                                </div>
                                <b-form-invalid-feedback
                                    v-if="!$v.categoria.codigo.maxLength"
                                >
                                    Este campo debe tener máximo
                                    {{
                                        $v.categoria.codigo.$params.maxLength
                                            .max
                                    }}
                                    caracteres
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback
                                    v-if="!$v.categoria.codigo.required"
                                >
                                    Campo requerido
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="9" lg="9" xl="9">
                            <b-form-group>
                                <label for="descripcion">Descripción</label>
                                <b-form-input
                                    id="descripcion"
                                    type="text"
                                    placeholder="Ej: Mi categoría"
                                    v-model="$v.categoria.descripcion.$model"
                                    :state="validateState('descripcion')"
                                    :disabled="read_only"
                                >
                                </b-form-input>
                                <div
                                    class="length-field mt-1"
                                    v-if="!$v.categoria.descripcion.$anyError"
                                >
                                    ({{
                                        $v.categoria.descripcion.$model.length
                                    }}
                                    /
                                    {{
                                        $v.categoria.descripcion.$params
                                            .maxLength.max
                                    }})
                                </div>
                                <b-form-invalid-feedback
                                    v-if="!$v.categoria.descripcion.maxLength"
                                >
                                    Este campo debe tener máximo
                                    {{
                                        $v.categoria.descripcion.$params
                                            .maxLength.max
                                    }}
                                    caracteres
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback
                                    v-if="!$v.categoria.descripcion.required"
                                >
                                    Campo requerido
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <div class="float-right">
                                <div class="d-flex flex-row">
                                    <b-button
                                        variant="primary"
                                        :disabled="isLoading || read_only"
                                        size="md"
                                        class="d-flex align-items-center"
                                        v-b-popover.hover.top="
                                            'Presione ctrl+shift+s o cmd+shift+s'
                                        "
                                        @click="save"
                                    >
                                        <feather
                                            type="save"
                                            size="1rem"
                                            class="mr-2 text-blue-active"
                                        />
                                        <span
                                            v-if="isLoading"
                                            class="d-sm-flex align-items-center justify-content-center"
                                        >
                                            <b-spinner small></b-spinner>
                                            <span class="ml-2"
                                                >Guardando...</span
                                            >
                                        </span>
                                        <span v-else>Guardar</span>
                                    </b-button>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-form>
            </div>
        </b-card>
    </b-modal>
</template>
<script>
import services from '@/boot/axios'
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'
import {
    addListenerCommands,
    removeListenerCommands,
    compareObjects,
    goToNextField
} from '@/utils/others'
import { mapGetters, mapState } from 'vuex'

const { API } = services

const defaultCategoria = () =>
    JSON.parse(
        JSON.stringify({
            codigo: '',
            descripcion: '',
            empresa_id: null
        })
    )

export default {
    name: 'CrearCategoria',
    mixins: [validationMixin],
    data: () => ({
        puc: [],
        categoriaCopy: defaultCategoria(),
        categoria: defaultCategoria(),
        isLoading: false,
        isSaved: true,
        read_only: false
    }),
    watch: {
        categoria: {
            handler(newValue) {
                this.isSaved = compareObjects(this.categoriaCopy, newValue)
            },
            deep: true
        }
    },
    mounted() {
        addListenerCommands(this)
        this.getParams()
    },
    beforeDestroy() {
        removeListenerCommands(this)
    },
    beforeRouteLeave(to, from, next) {
        if (this.isSaved) {
            next()
        } else {
            this.$bvModal
                .msgBoxConfirm(
                    `
        Este formulario contiene información que no ha sido guardada,
        si continua con esta acción no se guardarán los cambios, desea continuar con esta acción?`,
                    {
                        title: 'Advertencia!',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Continuar',
                        cancelTitle: 'Cancelar',
                        footerClass: 'p-2 d-flex justify-content-between',
                        hideHeaderClose: false,
                        centered: true,
                        headerBgVariant: 'danger',
                        headerClass: 'modal-header'
                    }
                )
                .then((value) => {
                    if (value) {
                        next()
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    },
    validations: {
        categoria: {
            codigo: {
                required,
                maxLength: maxLength(25)
            },
            descripcion: {
                required,
                maxLength: maxLength(150)
            }
        }
    },
    methods: {
        goToNextField,
        showLog(data) {
            console.log(data)
        },
        getParams() {
            const { categoria_id } = this.$route.params
            if (categoria_id) {
                this.getCategoriaById(categoria_id)
            }

            this.read_only = this.$route.meta.read_only
        },
        getCategoriaById(id) {
            // console.log('acá 2');
            API.POST({
                url: 'administracion/inventario/categoria/query',
                data: {
                    p_datajson: {
                        id
                    },
                    p_opc: 'FIND_BY_ID',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        // console.log(dato);
                        this.categoriaCopy = { ...dato }
                        this.categoria = { ...dato }
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        save() {
            const valid = !this.$v.categoria.$invalid
            if (valid) {
                this.isLoading = true
                const p_opc = this.categoria.id ? 'UPDATE' : 'INSERT'
                this.categoria.empresa_id = this.getUltimaEmpresa.id
                API.POST({
                    url: 'administracion/inventario/categoria/crud',
                    data: {
                        p_datajson: {
                            ...this.categoria
                        },
                        p_opc,
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                dato,
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        this.isLoading = false
                        if (status === 'ok') {
                            this.isSaved = true
                            // this.empresa = defaultEmpresa()
                            // this.back()
                            this.$emit('newCategoria', dato)
                            this.resetFom()
                            this.close()
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        this.isLoading = false
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            } else {
                this.$v.categoria.$touch()
            }
        },
        validateState(key) {
            const { $dirty, $error } = this.$v.categoria[key]
            return $dirty ? !$error : null
        },
        openModal() {
            this.goToNextField('codigo_cat')
        },
        resetFom() {
            this.categoria = defaultCategoria()
            this.$v.categoria.$reset()
        },
        close() {
            this.$bvModal.hide('create-categoria-modal')
        }
    },
    computed: {
        auditoriajson() {
            const json = { ...this.getAuditoriaJson, modulo_id: 9 }
            return json
        },
        ...mapGetters('usuario', ['getUserData', 'getAuditoriaJson']),
        ...mapGetters('empresas', ['getUltimaEmpresa']),
        ...mapState(['Theme'])
    }
}
</script>

<style>
.dialog {
    min-width: 80%;
}

.dialog2 {
    min-height: auto;
}
</style>
